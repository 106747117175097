<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-xl-7">
          <h1>Загрузка файлов</h1>
          <ThisComponent></ThisComponent>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/Upload'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--upload')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>
